<script setup>
import { passwordValidator } from "@/@core/utils/validators";
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useSuccessStore } from "@/composables/store/useSuccessStore";
import { useGenerateImageVariant } from "@core/composable/useGenerateImageVariant";
import atrLogo from "@images/logos/atr.png";
import authV2ForgotPasswordIllustrationDark from "@images/pages/auth-v2-forgot-password-illustration-dark.png";
import authV2ForgotPasswordIllustrationLight from "@images/pages/auth-v2-forgot-password-illustration-light.png";
import authV2MaskDark from "@images/pages/misc-mask-dark.png";
import authV2MaskLight from "@images/pages/misc-mask-light.png";

const route = useRoute();
const authStore = useAuthStore();
const successStore = useSuccessStore();
const router = useRouter();

const email = ref(route.query.email || "");
const password = ref("");
const token = ref(route.query.token || "");
const refVForm = ref(null);
const authThemeImg = useGenerateImageVariant(
  authV2ForgotPasswordIllustrationLight,
  authV2ForgotPasswordIllustrationDark
);
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark);

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

const init = async () => {
  if (!token.value && !email.value) {
    router.push({ name: "login" });
  }
};

const onSubmit = async () => {
  const isValid = await refVForm.value.validate();
  if (!isValid.valid) return;

  const resetPasswordResponse = await authStore.resetPassword({
    email: email.value,
    token: token.value,
    newPassword: password.value,
  });
  if (resetPasswordResponse) {
    successStore.setSuccessMessage("Password reset successfully");
    router.push({ name: "login" });
  }
};

init();
</script>

<template>
  <VRow class="auth-wrapper bg-surface" no-gutters>
    <VCol lg="8" class="d-none d-lg-flex">
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <VImg
            max-width="368"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg class="auth-footer-mask" :src="authThemeMask" />
      </div>
    </VCol>

    <VCol cols="12" lg="4" class="d-flex align-center justify-center">
      <VCard flat :max-width="500" class="mt-12 mt-sm-0 pa-4">
        <VCardText>
          <VImg class="pb-4" max-width="200px" :src="atrLogo" />
          <h4 class="text-h4 mb-1">Reset Password? 🔒</h4>
          <p class="mb-0">
            Enter your new password below to reset your password.
          </p>
        </VCardText>

        <VCardText>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="email"
                  autofocus
                  disabled
                  label="Email"
                  type="email"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="password"
                  autofocus
                  :rules="[requiredValidator, passwordValidator]"
                  label="Password"
                  type="password"
                />
              </VCol>

              <!-- Reset link -->
              <VCol cols="12">
                <VBtn
                  :loading="authStore.isResetPasswordLoading"
                  block
                  type="submit"
                >
                  Send Reset Link
                </VBtn>
              </VCol>

              <!-- back to login -->
              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  :to="{ name: 'login' }"
                >
                  <VIcon icon="tabler-chevron-left" class="flip-in-rtl" />
                  <span>Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
