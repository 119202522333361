<script setup>
import { useAgencyStore } from "@/composables/store/useAgencyStore";
import atrLogo from "@images/logos/atr.png";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  VCard,
  VCardText,
  VImg,
  VTab,
  VTabs,
  VWindow,
  VWindowItem,
} from "vuetify/components";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const router = useRouter();
const route = useRoute();
const agencyStore = useAgencyStore();

const { clientReports } = storeToRefs(agencyStore);

const currentTab = ref(0);
const reportsWithLinks = ref([]);

const init = async () => {
  try {
    const token = route.query.token;
    console.log("token", token);
    if (token) {
      const verifiedToken = await agencyStore.verifyPublicLinkToken(
        route.query.token
      );
      await agencyStore.getReportsByClient(verifiedToken.decoded.client_id);

      await Promise.all(
        clientReports.value.map(async (report) => {
          const { dashboard_id: dashboardId, inputs, platform, name } = report;
          const platforms = platform.split(",");

          for (const platform of platforms) {
            const accountId = inputs[platform];
            if (accountId) {
              const publicLink = await agencyStore.getOverviewReportUrl(
                dashboardId,
                inputs
              );
              reportsWithLinks.value.push({ name, publicLink });
            }
          }
        })
      );
    }
  } catch (error) {
    console.error("Initialization error:", error);
    // Optionally, handle errors and show a message to the user
  }
};

init();

const shareViaWhatsApp = () => {
  // Get the current page URL
  const currentPageUrl = window.location.href;

  // Encode the URL to ensure it is safely included in the query parameter
  const encodedUrl = encodeURIComponent(currentPageUrl);

  // Create a message to share
  const message = `Check out this public sharing link: ${currentPageUrl}`;

  // Construct the WhatsApp share URL
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    message
  )}`;

  // Open the WhatsApp share URL in a new tab
  window.open(whatsappUrl, "_blank");
};
</script>
<template>
  <div class="flex flex-col gap-8">
    <v-card>
      <div class="flex flex-col gap-8 p-4 lg:p-8 lg:mx-20">
        <div
          class="flex lg:flex-col flex-row items-center justify-between gap-2"
        >
          <div class="flex items-center justify-center">
            <RouterLink to="/" class="flex items-center">
              <VImg class="w-48" :src="atrLogo" />
            </RouterLink>
          </div>
          <!-- Share Button -->
          <div class="flex items-center share-button">
            <VBtn
              @click="shareViaWhatsApp"
              class="self-center flex justify-end text-sm md:text-base"
              color="success"
            >
              <div class="flex items-center gap-1">
                <v-icon class="tabler-brand-whatsapp"></v-icon>
                <p class="text-[10px]">Share via WhatsApp</p>
              </div>
            </VBtn>
          </div>
        </div>
        <div
          class="flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0 lg:space-x-20 p-3"
        >
          <h3 class="text-lg lg:text-2xl font-semibold">
            Agency Name :
            <span class="text-primary font-bold">
              {{
                clientReports?.[0]?.agency_clients?.agency?.name?.toUpperCase()
              }}</span
            >
          </h3>

          <h3 class="text-lg lg:text-2xl font-semibold">
            Client Name :
            <span class="text-primary font-bold">
              {{
                clientReports?.[0]?.company_dashboards?.[0]?.company?.name.toUpperCase()
              }}</span
            >
          </h3>
        </div>
      </div>
    </v-card>

    <div class="flex justify-center">
      <div class="w-full lg:w-4/5">
        <VCard :loading="agencyStore.isReportLoading">
          <VTabs v-model="currentTab" height="50">
            <VTab
              v-for="(report, index) in reportsWithLinks"
              :key="index"
              class="text-sm"
              base-color="#333"
              color="#3B3977"
              ripple="true"
            >
              <p class="m-auto text-primary">
                {{ report.name }}
              </p>
            </VTab>
          </VTabs>
          <VCardText>
            <VWindow v-model="currentTab">
              <VWindowItem
                v-for="(report, index) in reportsWithLinks"
                :key="index"
              >
                <iframe
                  class="w-full h-screen lg:h-[80vh] border-none"
                  :src="report.publicLink"
                  frameborder="0"
                ></iframe>
              </VWindowItem>
            </VWindow>
          </VCardText>
        </VCard>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header {
  @apply flex justify-between lg:px-24;
}

@media (min-width: 1024px) {
  .header {
    @apply justify-center;
  }

  .share-button {
    @apply justify-end;
  }
}

@media (max-width: 1023px) {
  .header {
    @apply flex-row justify-between;
  }

  .share-button {
    @apply justify-between;
  }
}
</style>
