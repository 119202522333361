<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useErrorStore } from "@/composables/store/useErrorStore";
import { useSocialConnection } from "@/composables/store/useSocialConnection";
import { onMounted } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const route = useRoute();
const router = useRouter();
const socialConnection = useSocialConnection();
const authStore = useAuthStore();
// Decode this %7B%22agency_id%22%3A%22%22%7D" to {"agency_id":""}
//
const agencyId = JSON.parse(decodeURIComponent(route.query?.state))?.agency_id;
const clientId = JSON.parse(decodeURIComponent(route.query?.state))?.client_id;

console.log(decodeURIComponent(route.query.state));
console.log("route.query", agencyId);

// Sample Reference: http://localhost:5173/googleads/oauthcallback?state=30d0ffbb566c374d91465565ac302b97477fde5d0926ffc6d78651cb767dc982&code=4/0AfJohXm0kLMr5IRmoKZgOYXd0Tbrd6LlKndH3LvtFzaPBH1tFQqkI6ETtLRgo0JijcpNjg&scope=https://www.googleapis.com/auth/adwords
const saveGoogleAuthToken = async () => {
  if (!route?.query?.code) {
    useErrorStore().setError("Invalid Google OAuth code");
  }
  await socialConnection.finishGoogleOAuth(
    route.query.code,
    agencyId,
    clientId
  );

  setTimeout(() => {
    console.log("redirecting to clients-settings");
    if (authStore.company.company_type === "agency") {
      router.push({
        name: "pages-request-access",
      });
    } else {
      router.push({
        name: "pages-client-request-access",
      });
    }
  }, 3000);
};

onMounted(() => {
  saveGoogleAuthToken();
});
</script>

<template>
  <div class="text-center" style="margin-top: 200px">
    <h1 class="mb-10">Redirecting back to app</h1>
    <VProgressCircular size="100" indeterminate color="primary" />
  </div>
</template>
