import { useAuthStore } from '@/composables/store/useAuthStore';
import { useCategoryStore } from '@/composables/store/useCategoryStore';
import { LOCALSTORAGE } from '@/utils/constants';

export const setupGuards = router => {
  router.beforeEach((to, from, next) => {
    const { initAuthData } = useAuthStore()
    const { initCategories } = useCategoryStore()

    // Allow navigation to public routes
    if (to.meta.public) {
      next();
      return;
    }

    // Check if user is logged in
    const isLoggedIn = !!(
      localStorage.getItem(LOCALSTORAGE.USER_ID) &&
      localStorage.getItem(LOCALSTORAGE.COMPANY_ID) &&
      localStorage.getItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN)
    );

    // If the user is logged in, initialize user data
    if (isLoggedIn) {
      initAuthData();
      initCategories()
    }

    // Redirect logged in users away from unauthenticated only pages
    if (to.meta.unauthenticatedOnly) {
      if (isLoggedIn) {
        next('/');
        return;
      } else {
        next();
        return;
      }
    }

    // If not logged in, redirect to login page
    if (!isLoggedIn) {
      next({
        name: 'login',
        query: {
          to: to.fullPath !== '/' ? to.fullPath : undefined,
        },
      });
      return;
    }

    // Check for permissions if user is logged in
    // if (!canNavigate(to)) {
    //   console.log(to, 'is logged in', isLoggedIn);
    //   next({
    //     name: 'login',
    //     query: {
    //       to: to.fullPath !== '/' ? to.fullPath : undefined,
    //     },
    //   });
    //   return;
    // }

    // If all checks pass, proceed to the route
    next();
  });
};
