<script setup>
import { useClientStore } from "@/composables/store/useClientStore";
import { useProjectStore } from "@/composables/store/useProjectStore";
import { useTaskStore } from "@/composables/store/useTaskStore";
import ProjectTable from "@/views/demos/forms/tables/data-table/ProjectTable.vue";
import TaskTable from "@/views/demos/forms/tables/data-table/TaskTable.vue";
import ActivityTimeline from "@/views/pages/user-profile/profile/ActivityTimeline.vue";
import UserProfile from "@/views/pages/user-profile/profile/index.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

definePage({
  meta: {
    navActiveLink: "pages-user-profile-tab",
    key: "tab",
  },
});

const route = useRoute();
const router = useRouter();
const clientStore = useClientStore();
const projectStore = useProjectStore();
const taskStore = useTaskStore();

const clientProjects = ref([]);
const clientTasks = ref([]);

const init = async () => {
  clientProjects.value = await projectStore.getClientProjects(route.params.id);
  clientTasks.value = await taskStore.getClientTasks(route.params?.id);
};

init();

const activeTab = computed({
  get: () => route.params.tab,
  set: () => route.params.tab,
});

// tabs
const filterTabs = [
  {
    name: "Profile",
    value: "profile",
  },
  {
    name: "Projects",
    value: "projects",
  },

  {
    name: "History",
    value: "history",
  },
];

const selectedFilterTab = ref("profile");

const updateTabUrl = (tabValue) => {
  router.push({
    name: "pages-client-id-tab",
    params: { tab: tabValue },
  });
};
</script>

<template>
  <div>
    <VBreadcrumbs
      :items="[
        { title: 'Dashboard', to: { name: 'dashboards-analytics' } },
        { title: 'Clients', to: { name: 'pages-client' } },
        { title: `${clientStore.client?.name || ''}`, active: true },
      ]"
    ></VBreadcrumbs>
    <PageHeader
      title="Clients"
      lastUpdated="2024-02-17"
      description="Are you ready to take control of your client management? With our platform, you can seamlessly add and manage your clients to ensure smooth operations and excellent service delivery."
    >
    </PageHeader>

    <VRow>
      <VCol cols="6">
        <div class="d-flex">
          <VCard>
            <VBtn
              v-for="tab in filterTabs"
              :key="tab.value"
              color="primary"
              :variant="selectedFilterTab === tab.value ? 'tonal' : 'contained'"
              class="text-capitalize font-weight-medium px-10"
              @click="selectedFilterTab = tab.value"
            >
              {{ tab.name }}
            </VBtn>
          </VCard>
        </div>
      </VCol>
    </VRow>

    <VWindow
      :model-value="selectedFilterTab"
      class="mt-5 disable-tab-transition"
      :touch="false"
    >
      <!-- Profile -->
      <VWindowItem value="profile">
        <UserProfile />
      </VWindowItem>

      <!-- Projects -->
      <VWindowItem value="projects">
        <ProjectTable :data="clientProjects" />
      </VWindowItem>

      <!-- Tasks -->
      <VWindowItem value="tasks">
        <TaskTable :data="clientTasks" />
      </VWindowItem>

      <!-- History -->
      <VWindowItem value="history">
        <ActivityTimeline />
      </VWindowItem>
    </VWindow>
  </div>
</template>
