export default function (app) {
  // Sentry.init({
  //   app,
  //   dsn: "https://1c028e5dc186523e0150f6ebe6f56d0d@o4507389985095680.ingest.us.sentry.io/4507390056398848",
  //   integrations: [
  //     Sentry.browserTracingIntegration({ router: router }),
  //     //   Sentry.replayIntegration(),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["*"],
  //   environment: import.meta.env.VITE_ENV,
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });
}
