import { AbilityBuilder, Ability } from "@casl/ability";
import { Roles, RoleSubjects, LOCALSTORAGE } from "../../utils/constants";

export function defineAbilitiesFor(role) {
    const { can, cannot, build } = new AbilityBuilder(Ability)
  
    function allowAllActions(subjects) {
      can('delete', subjects)
      can('update', subjects)
      can('create', subjects)
      can('read', subjects)
    }
  
    function allowUpdateActions(subjects) {
      can('update', subjects)
      can('create', subjects)
      can('read', subjects)
    }
  
    function allowCreateActions(subjects) {
      can('create', subjects)
      can('read', subjects)
    }
  
    function allowReadActions(subjects) {
      can('read', subjects)
    }

    function disAllowAllActions(subjects) {
        cannot('delete', subjects)
        cannot('update', subjects)
        cannot('create', subjects)
        cannot('read', subjects)
    }

    function disAllowUpdateActions(subjects) {
        cannot('update', subjects)
        cannot('create', subjects)
        cannot('read', subjects)
    }

    function disAllowCreateActions(subjects) {
        cannot('create', subjects)
        cannot('read', subjects)
    }

    function disAllowReadActions(subjects) {
        cannot('read', subjects)
    }
  
    switch (role) {
      case Roles.SuperAdmin:
        allowAllActions(Object.values(RoleSubjects))
      case Roles.AgencyAdmin:
        allowAllActions(Object.values(RoleSubjects))
      case Roles.ClientAdmin:
        allowAllActions(Object.values(RoleSubjects))
        break
      case Roles.AgencyManager:
        allowAllActions(Object.values(RoleSubjects))
        disAllowAllActions([RoleSubjects.AgencyCompanySettings, RoleSubjects.AgencyPricing])
        break
      case Roles.AgencyViewer:
        allowUpdateActions([RoleSubjects.AgencyProject, RoleSubjects.AgencyBrief, RoleSubjects.AgencyReports])
        break
      case Roles.ClientManager:
        allowAllActions(Object.values(RoleSubjects))
        disAllowAllActions([RoleSubjects.ClientCompanySettings])
        break
      case Roles.ClientViewer:
        allowReadActions(Object.values(RoleSubjects))
        allowUpdateActions([RoleSubjects.ClientProject, RoleSubjects.ClientBrief, RoleSubjects.ClientReports])
        break
      default:
        allowReadActions(Object.values(RoleSubjects))
    }
  
    return build()
  }

const ability = defineAbilitiesFor(localStorage.getItem(LOCALSTORAGE.USER_ROLE) || Roles.SuperAdmin); // Default role

export default ability;
