import { createMongoAbility } from '@casl/ability'
import { abilitiesPlugin } from '@casl/vue'
import { useStorage } from '@vueuse/core'
import ability from "./ability";

export default function (app) {
  app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  });
}
