<script setup>
import About from "@/views/pages/user-profile/profile/About.vue";
import ActivityTimeline from "@/views/pages/user-profile/profile/ActivityTimeline.vue";
import BriefManagementList from "@/views/pages/user-profile/profile/BriefManagementList.vue";
import Connection from "@/views/pages/user-profile/profile/Connection.vue";
import DataManagementList from "@/views/pages/user-profile/profile/DataManagementList.vue";
import StaffList from "@/views/pages/user-profile/profile/StaffList.vue";

const profileTabData = ref({});
const selectedAgency = ref(null);

definePage({
  meta: {
    navActiveLink: "pages-user-profile-tab",
    key: "tab",
  },
});

const route = useRoute();
</script>

<template>
  <div>
    <VRow>
      <VCol class="d-flex" cols="12" md="12">
        <VBreadcrumbs
          :items="[
            { title: 'Home', to: { name: 'pages-client' } },
            { title: `Settings`, active: true },
          ]"
        ></VBreadcrumbs>
      </VCol>
    </VRow>
    <VRow v-if="profileTabData">
      <VCol md="4" cols="12">
        <VRow>
          <VCol cols="12" md="12">
            <About :data="profileTabData" />
          </VCol>
          <VCol cols="12" md="12">
            <StaffList />
          </VCol>
          <VCol cols="12" md="12">
            <ActivityTimeline />
          </VCol>
        </VRow>
      </VCol>

      <VCol cols="12" md="8">
        <VRow>
          <VCol cols="12" md="12">
            <Connection />
          </VCol>
          <VCol cols="12">
            <DataManagementList />
          </VCol>
          <VCol cols="12">
            <BriefManagementList />
          </VCol>
          <!-- <VCol cols="12">
          <ActivityTimeline />
        </VCol> -->

          <!-- <VCol
          cols="12"
          md="6"
        >
          <Teams :teams-data="profileTabData.teamsTech" />
        </VCol> -->

          <!-- <VCol cols="12">
          <ProjectList />
        </VCol> -->
        </VRow>
      </VCol>
    </VRow>
  </div>
</template>
