<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import AuthProvider from "@/views/pages/authentication/AuthProvider.vue";
import atrLogo from "@images/logos/atr.png";
import { inject } from "vue";
import { VForm } from "vuetify/components/VForm";

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

const posthog = inject("posthog");

const environment =
  import.meta.env.VITE_ENV === "development" ? "Development" : null;
const isPasswordVisible = ref(false);
const route = useRoute();
const router = useRouter();
const ability = useAbility();
const { signIn, updateSigninUserData, signin, googleCallback } = useAuthStore();

const googleAuthCode = ref(route.query.code);

const errors = ref({
  email: undefined,
  password: undefined,
});

const refVForm = ref();

const user = ref({
  // email: "suzaliikmal@gmail.com",
  // password: "Soccer12",
  // email: "test1234@gmail.com",
  // password: "Soccer12",
});

// const rememberMe = ref(false)

const onSubmit = async () => {
  const isValid = await refVForm.value?.validate();
  if (!isValid.valid) return;

  updateSigninUserData({
    email: user.value.email,
    password: user.value.password,
  });

  await signIn();

  await nextTick(() => {
    console.log("router", router);
    router.replace(route.query.to ? String(route.query.to) : "/");
  });
};

const init = async () => {
  try {
    if (googleAuthCode.value) {
      const googleCallbackResponse = await googleCallback(googleAuthCode.value);
      if (googleCallbackResponse.data.redirect === "signup") {
        router.push({
          name: "register",
          query: {
            user_email: googleCallbackResponse.data.email,
            name: googleCallbackResponse.data.name,
          },
        });
      } else {
        router.push("/");
      }
    } else {
      router.push("/");
    }
  } catch (error) {
    router.push("/");
  }
};

console.log("Created posthog", posthog); //posthog accessible anywhere!

init();
</script>

<template>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
    style="
      background: linear-gradient(10deg, #fafafa 10%, #3b397f 80%);
      height: 100vh;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <VCol
      cols="12"
      lg="12"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard flat :width="600" :elevation="10" class="mt-12 mt-sm-0 pa-4">
        <VCardText>
          <span>
            <VImg class="pb-4" max-width="200px" :src="atrLogo" />
            <h2 class="text-h2 mb-1">{{ environment }}</h2>
          </span>

          <!-- <h4 class="text-h4 mb-1">
            Welcome to
            <span class="text-capitalize"> {{ themeConfig.app.title }} </span>!
            👋🏻
          </h4> -->
          <p class="mb-0">Please sign-in to your account</p>
        </VCardText>
        <!-- <VCardText>
          <VAlert
            color="primary"
            variant="tonal"
          >
            <p class="text-sm mb-2">
              Admin Email: <strong>admin@demo.com</strong> / Pass: <strong>admin</strong>
            </p>
            <p class="text-sm mb-0">
              Client Email: <strong>client@demo.com</strong> / Pass: <strong>client</strong>
            </p>
          </VAlert>
        </VCardText> -->
        <VCardText>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="user.email"
                  :disabled="signin.isLoading"
                  label="Email"
                  placeholder="johndoe@email.com"
                  type="email"
                  autofocus
                  :rules="[requiredValidator, emailValidator]"
                  :error-messages="errors.email"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <AppTextField
                  v-model="user.password"
                  label="Password"
                  :disabled="signin.isLoading"
                  placeholder="············"
                  :rules="[requiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="errors.password"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />

                <div
                  class="d-flex align-center flex-wrap justify-space-between mt-1 mb-4"
                >
                  <!-- <VCheckbox
                    v-model="rememberMe"
                    label="Remember me"
                  /> -->
                  <RouterLink
                    class="text-primary ms-2 mb-1"
                    :to="{ name: 'forgot-password' }"
                  >
                    Forgot Password?
                  </RouterLink>
                </div>

                <VBtn :loading="signin.isLoading" block type="submit">
                  Login
                </VBtn>
              </VCol>

              <VCol cols="12" class="d-flex align-center">
                <VDivider />
                <span class="mx-4">or</span>
                <VDivider />
              </VCol>

              <VCol cols="12" class="text-center">
                <AuthProvider :is-google-btn-loading="!!googleAuthCode" />
              </VCol>
            </VRow>
          </VForm>
          <!-- create account -->
          <VCol cols="12" class="text-center">
            <span>New on our platform?</span>
            <RouterLink class="text-primary ms-2" :to="{ name: 'register' }">
              Create an account
            </RouterLink>
          </VCol>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
