<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { onMounted } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const router = useRouter();
const authStore = useAuthStore();

// Get the company details
// Run every 1 seconds and check if the current subscription key is different from the previous one
// Loop max 5 times
// If it is different, redirect to the appropriate page

const saveMetaAuthToken = async () => {
  let count = 0;
  const interval = setInterval(async () => {
    const previousSubscriptionKey = authStore.subscription.key;
    const company = await authStore.getCompany();
    if (count >= 5) {
      clearInterval(interval);
      router.push({
        name: "pages-request-access",
        query: {
          error: "subscription_not_updated",
        },
      });
    }
    if (previousSubscriptionKey !== company.subscription.key) {
      clearInterval(interval);
      router.push({
        name: "pages-request-access",
      });
    }
    count++;
  }, 1000);
};

onMounted(async () => {
  authStore.getCompany();
  await saveMetaAuthToken();
});
</script>

<template>
  <div class="text-center" style="margin-top: 200px">
    <h1 class="mb-10">Redirecting back to app</h1>
    <VProgressCircular size="100" indeterminate color="primary" />
  </div>
</template>
