import App from "@/App.vue";
import { router } from "@/plugins/1.router/index.js";
import { registerPlugins } from "@core/utils/plugins";
import "@formkit/themes/genesis";
import { defaultConfig, plugin } from "@formkit/vue";
import "@vuepic/vue-datepicker/dist/main.css";

import posthog from "posthog-js";

import { createApp } from "vue";

// Styles
import "@core/scss/template/index.scss";
import "@styles/styles.scss";
import "@vue-flow/controls/dist/style.css";
import "@vue-flow/core/dist/style.css";
import "@vue-flow/core/dist/theme-default.css";

// Create vue app
const app = createApp(App);

posthog.init("phc_o4TwCBOr1iqs0SNjCMMsoSNL5pO4SY2BKCtp3Pof5ua", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only",
});
app.provide("posthog", posthog);

// Facebook Login
window.fbAsyncInit = function () {
  FB.init({
    appId: import.meta.env.VITE_FACEBOOK_APP_ID,
    cookie: true,
    xfbml: true,
    version: "v19.0", // Update with a valid version
  });

  FB.AppEvents.logPageView();
};

// Load the SDK script
(function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");

app.use(plugin, defaultConfig);

// Register plugins
registerPlugins(app);

console.log("App", app);
console.log("Sentry plugin loaded", router);

// Sentry.init({
//   app,
//   dsn: "https://1c028e5dc186523e0150f6ebe6f56d0d@o4507389985095680.ingest.us.sentry.io/4507390056398848",
//   integrations: [
//     Sentry.browserTracingIntegration({ router }),

//     //   Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["*"],

//   environment: import.meta.env.VITE_ENV,
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Mount vue app
app.mount("#app");
